import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/impresions.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2>
          impregnating and securing upholstery with a protective coating
          <span className="uslugi__text-title"> </span>
        </h2>
        <p>
          Did you know that proper protection of upholstery and carpets allows
          you to enjoy their beautiful appearance and functionality for many
          years? Impregnation is an effective way to protect the material
          against stains, moisture and damage, as well as to facilitate daily
          care.
        </p>
        <h3>Why is impregnation a necessity?</h3>

        <p>
          <em>No stress when spilling liquids </em> - the protective coating
          prevents liquids from penetrating.
        </p>
        <p>
          <em> Furniture resistant to everyday use </em>- less abrasion and
          damage.
        </p>
        <p>
          <em>A well-kept interior without any effort </em> - cleaning becomes
          simple and quick.
        </p>

        <h3>Why is it worth trusting us?</h3>
        <p>
          We are experts in upholstery protection. We use modern technologies
          and safe preparations that work effectively and are gentle on
          materials.
        </p>
        <p>
          Protect your furniture and carpets for the future - choose
          impregnation!
        </p>
        <p className="uslugi-notes">
          Please contact us and obtain an individual offer: <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
