import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/clean_chair.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2 className="uslugi__text-h2">
          PRANIE KRZESŁA
          <span className="uslugi__text-title">
            Fotela, krzesła biurowego, pufa
          </span>
        </h2>
        <p>
          Masz w domu lub biurze brudne krzesła czy fotele? Umów się na wizytę z
          naszym pracownikiem i bądź pewien, że usuniemy brud bezpiecznie dla
          tkanin i dla mieszkańców.
        </p>
        <p>
          Firma Dobra Atmosfera oferuje profesjonalne pranie krzeseł, krzeseł
          biurowych, foteli, puf.
        </p>

        <p>
          Dzięki nowoczesnym technologiom i naszemu doświadczeniu gwarantujemy
          perfekcyjną czystość oraz wydłużenie żywotności Twoich mebli.
        </p>
        <p>Czyścimy meble wykonanych z tkanin, skóry, eko skóry.</p>
        <h3>Dlaczego warto wybrać nas?</h3>
        <p className="p-span">
          Jakość:
          <span>
            Korzystamy z bezpiecznych i skutecznych środków czyszczących, które
            nie uszkadzają materiałów.
          </span>
        </p>
        <p className="p-span">
          Szybkość:
          <span>
            Prace wykonujemy sprawnie, minimalizując zakłócenia w funkcjonowaniu
            biura.
          </span>
        </p>
        <p className="p-span">
          Przystępność:
          <span>
            Elastyczne warunki współpracy i przejrzysta polityka cenowa.
          </span>
        </p>
        <p className="p-span">
          Gwarancja efektu:
          <span>
            Usuwamy plamy, nieprzyjemne zapachy i przywracamy świeżość meblom.
          </span>
        </p>

        <h3>Zakres naszych usług obejmuje:</h3>
        <ol className="noitalic-ol-ul">
          <li>
            Pranie i czyszczenie foteli i krzeseł z pomocą profesjonalnej chemii
          </li>
          <li> Usuwanie plam i ciężkich zabrudzeń </li>
          <li>Wypłukanie chemii </li>
          <li>Neutralizacja pozostałości chemicznych</li>
          <li>
            Na życzenie zastosujemy na meblach profesjonalny środek ochronny,
            który odpycha brud i wodę i pomaga utrzymać ich czystość i świeżość
            na dłużej.
          </li>
          <li>
            Podsuszenie mebli, aby skrócić czas jej całkowitego wysychania.
          </li>
        </ol>
        <h3>Warunki współpracy:</h3>
        <ul>
          <li>
            {' '}
            Indywidualna wycena, dostosowana do ilości i specyfiki mebli.
          </li>
          <li>
            {' '}
            Możliwość realizacji usług w dogodnym dla Państwa czasie (również w
            weekendy).
          </li>
          <li>Zniżki dla stałych klientów i przy większych zamówieniach.</li>
        </ul>
        <p>
          Zaufaj profesjonalistom i zadbaj o czystość swoich krzeseł i foteli! Z
          przyjemnością odpowiemy na wszelkie pytania i przygotujemy ofertę
          dostosowaną do Państwa potrzeb.
        </p>
        <p className="uslugi-notes">
          Zapraszamy do kontaktu: <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </p>
      </div>
    </>
  );
}
// i uzyskania indywidualnej oferty
function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
