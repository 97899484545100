import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/clean_sofa.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2>
          Washing the couch
          <span className="uslugi__text-title">
            {' '}
            Sofas, sofa beds, corner sofas
          </span>
        </h2>
        <p>
          Are you looking for a high-quality cleaning service for your couch?
          You've come to the right place!
        </p>
        <p>
          Dobra Atmosfera offers professional washing of couches, sofas, sofas
          and corner sofas. We will clean both ordinary, everyday dirt and
          difficult stains from blood, glue, tea, coffee, wine, ink, fruit and
          berries.
          <br />
          We will remove the unpleasant smell of urine, mold or vomit from your
          sofa.
        </p>

        <p>
          Our main goal is to keep your sofa clean and safe to use for as long
          as possible! For this purpose, we use professional equipment and the
          best cleaning agents on the market.
        </p>
        <p>
          On request,{' '}
          <strong> we will use a specialized protective preparation </strong>{' '}
          that repels dirt and water, helping to keep the sofa clean and fresh
          for a long time.
        </p>
        <p>
          Additionally, <strong> we will dry the sofa </strong> to shorten the
          time it takes to dry completely. By choosing our services, you can be
          sure that your sofa will regain its fresh look and will serve for many
          years.
        </p>
        <p>
          Contact us today to learn more about the offer and make an
          appointment.
        </p>
        <p className="uslugi-notes">
          Please contact us and obtain an individual offer: <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
