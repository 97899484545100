import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/clean_leather.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2>
          czyszczenie i pielęgnacja tapicerki skórzanej
          <span className="uslugi__text-title"> </span>
        </h2>
        <p>
          Szukasz specjalisty w czyszczeniu i ochronie Twoich mebli skórzanych
          czy tapicerki samochodowej? Już znalazłeś!
        </p>
        <p>Twoja tapicerka skórzana – nasza pasja!</p>
        <p>
          Bez wątpienia skórzana tapicerka prezentuje się oszałamiająco i
          niezwykle luksusowo, ale wymaga odpowiedniej opieki, aby zawsze
          wyglądała jak nowa.{' '}
        </p>
        <p>
          Zamów się na czyszczenie i specjalista z firmy Dobra Atmosfera sprawi,
          że Twoja skórzana tapicerka znowu będzie lśnić czystością.
        </p>
        <p>
          {' '}
          Zajmiemy się wszystkim – czyszczeniem, pielęgnacją i ochroną, a Ty
          będziesz cieszyć się odświeżonym wnętrzem bez wysiłku!
        </p>
        <h3>Jak to robimy?</h3>
        <ol>
          <li className="p-span">Delikatne przygotowanie.</li>
          <p className="ol-p">
            Zaczynamy od dokładnego usunięcia kurzu i luźnych zabrudzeń. Używamy
            profesjonalnych narzędzi i technik, które są bezpieczne nawet dla
            najbardziej wymagających rodzajów skóry.
          </p>
          <li className="p-span">Skuteczne czyszczenie.</li>
          <p className="ol-p">
            Stosujemy wyłącznie najwyższej jakości środki do pielęgnacji skóry,
            które nie tylko usuwają brud, ale również chronią jej strukturę.
            Nasi specjaliści wiedzą, jak radzić sobie nawet z najtrudniejszymi
            plamami, nie ryzykując uszkodzenia tapicerki.
          </p>
          <li className="p-span">Pielęgnacja i regeneracja.</li>
          <p className="ol-p">
            Po czyszczeniu skóra wymaga odpowiedniej pielęgnacji. Nakładamy
            profesjonalne odżywki, które nawilżają materiał, przywracają mu
            elastyczność i zapobiegają pękaniu. To prawdziwy zabieg SPA dla
            Twojej tapicerki!
          </p>
          <li className="p-span">Ochrona i połysk.</li>
          <p className="ol-p">
            Na Twoje życzenie, nakładamy środki ochronne, które tworzą barierę
            przed zabrudzeniami i promieniami UV. Dzięki temu Twoja tapicerka
            dłużej zachowa swój idealny wygląd.
          </p>
          <li className="p-span">Długotrwały efekt.</li>
          <p className="ol-p">
            Regularna i terminowa pielęgnacja to klucz do trwałości. Oferujemy
            również porady, jak najlepiej dbać o skórę między naszymi wizytami.
          </p>
        </ol>
        <p> Twoja skórzana tapicerka zasługuje na najlepszą opiekę.</p>
        <p className="uslugi-notes">
          Zadzwoń do nas i przekonaj się, jak możemy odnowić jej piękno!
          <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
