import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/clean_leather.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2>
          cleaning and care of leather upholstery
          <span className="uslugi__text-title"> </span>
        </h2>
        <p>
          Are you looking for a specialist in cleaning and protecting your
          leather furniture or car upholstery? You've already found it!
        </p>
        <p>Your leather upholstery – our passion!</p>
        <p>
          There is no doubt that leather upholstery looks stunning and extremely
          luxurious, but it requires proper care to keep it looking like new.
        </p>
        <p>
          Order cleaning and a specialist from Dobra Atmosfera will make your
          leather upholstery sparkle clean again.
        </p>
        <p>
          We will take care of everything - cleaning, care and protection, and
          you will enjoy a refreshed interior without any effort!
        </p>
        <h3>How do we do it?</h3>
        <ol>
          <li className="p-span">Gentle preparation.</li>
          <p className="ol-p">
            We start by thoroughly removing dust and loose dirt. We use
            professional tools and techniques that are safe even for the most
            demanding skin types.
          </p>
          <li className="p-span">Effective cleaning.</li>
          <p className="ol-p">
            We use only the highest quality leather care products that not only
            remove dirt, but also protect its structure. Our specialists know
            how to deal with even the most difficult stains without risking
            damage to the upholstery.
          </p>
          <li className="p-span">Care and regeneration.</li>
          <p className="ol-p">
            After cleaning, the leather requires proper care. We apply
            professional conditioners that moisturize the material, restore its
            elasticity and prevent cracking. It's a real SPA treatment for your
            upholstery!
          </p>
          <li className="p-span">Protection and shine.</li>
          <p className="ol-p">
            At your request, we apply protective agents that create a barrier
            against dirt and UV rays. Thanks to this, your upholstery will
            maintain its perfect appearance for longer.
          </p>
          <li className="p-span">Long-lasting effect.</li>
          <p className="ol-p">
            Regular and timely care is the key to durability. We also offer
            advice on how to best care for your skin between our visits.
          </p>
        </ol>
        <p> Your leather upholstery deserves the best care.</p>
        <p className="uslugi-notes">
          Call us and see how we can restore its beauty!
          <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
