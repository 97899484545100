import './Services.css';
import main1 from '../../../img/Services/clean_sofa.jpg';
import main4 from '../../../img/Services/clean_carpet3.jpg';
import main3 from '../../../img/Services/tapicerka.jpg';
import main2_1260px from '../../../img/Services/usluga2_1260px.png';
import main5 from '../../../img/Services/clean_matrac.jpg';
import main6 from '../../../img/Services/window5.jpg';
import main7 from '../../../img/Services/impresions.jpg';
import main8 from '../../../img/Services/clean_leather.jpg';
import { Link } from 'react-router-dom';

function TitleServiceless850px() {
  return (
    <div className=" main-services">
      <h3 className="h3">PROFESSIONAL WASHING</h3>
      <h1 className="h1">
        <span className="en-titlerow">
          COUCHES, CHAIRS, MATTRESSES, CARPETS,
        </span>
        <span className="en-titlerow">CARPETS, TROLLEYS, CURTAINS,</span>
        <span className="en-titlerow">CAR UPHOLSTERY...</span>
      </h1>
      <h2 className="h2">
        👉 WE GUARANTEE A 100% REFUND IF THE CUSTOMER IS NOT SATISFIED WITH THE
        WASHING EFFECT!!!
      </h2>
    </div>
  );
}

function Services() {
  return (
    <>
      <div className="main__first-services">
        <TitleServiceless850px />

        <div className="main__services">
          <Link to="/en/usluga1">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img">
                <img src={main1} alt="photo1" />
              </div>
              <h2>
                Washing the couch
                <span className="main__services-item-span">
                  Sofas, sofa beds, corner sofas
                </span>
              </h2>

              <button type="button" className="main-services-price">
                from 150 pln
              </button>
            </div>
          </Link>

          <Link to="/en/usluga2">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img usluga2-bock-wide">
                <img src={main2_1260px} alt="photo1" className="usluga2-img" />
              </div>
              <h2>
                WASHING THE CHAIR
                <span className="main__services-item-span">
                  armchair, office chair, pouffe
                </span>
              </h2>
              <button type="button" className="main-services-price">
                from 20 pln
              </button>
            </div>
          </Link>
          <Link to="/en/usluga3">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img">
                <img src={main3} alt="photo1" />
              </div>
              <h2>
                Washing car upholstery
                <span className="main__services-item-span">Seat cleaning</span>
              </h2>

              <button type="button" className="main-services-price">
                from 50 pln
              </button>
            </div>
          </Link>
          <Link to="/en/usluga4">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img">
                <img src={main4} alt="photo1" />
              </div>
              <h2>
                WASHING <br /> CARPETS, CARPETS
                <span className="main__services-item-span"></span>
              </h2>

              <button type="button" className="main-services-price">
                from 8 pln
              </button>
            </div>
          </Link>
          <Link to="/en/usluga5">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img">
                <img src={main5} alt="photo1" />
              </div>
              <h2>
                CLEANING MATTRESS
                <span className="main__services-item-span"></span>
              </h2>

              <button type="button" className="main-services-price">
                from 100 pln
              </button>
            </div>
          </Link>
          <Link to="/en/usluga8">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img">
                <img src={main8} alt="photo1" />
              </div>
              <h2>
                cleaning and care of leather upholstery
                <span className="main__services-item-span"></span>
              </h2>

              <button type="button" className="btn-service"></button>
            </div>
          </Link>
          <Link to="/en/usluga7">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img">
                <img src={main7} alt="photo1" />
              </div>
              <h2>
                impregnating and securing upholstery with a protective coating
                <span className="main__services-item-span"></span>
              </h2>

              <button type="button" className="btn-service">
                from 100 pln
              </button>
            </div>
          </Link>
          <Link to="/en/usluga6">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img">
                <img src={main6} alt="photo1" />
              </div>
              <h2>
                window washing
                <span className="main__services-item-span"></span>
              </h2>

              <button type="button" className="main-services-price">
                from 30 pln
              </button>
            </div>
          </Link>
        </div>
        <div className="main__link-details">
          <Link to="/en/price">More details...</Link>
        </div>
      </div>
    </>
  );
}
export default Services;
