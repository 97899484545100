import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/clean_carpet3.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2>
          WASHING CARPETS AND CARPETS
          <span className="uslugi__text-title"> </span>
        </h2>
        <p>
          Do you need professional carpet cleaning? You've come to the right
          place!
        </p>
        <p>
          Dobra Atmosfera offers professional carpet cleaning services in your
          home or office.
        </p>
        <p>
          Thanks to modern technologies, we effectively remove everyday dirt,
          stains, dust and unpleasant odors, restoring the freshness and
          aesthetic appearance of the surface.
        </p>
        <h3>Why is it worth trusting us?</h3>
        <ul>
          <li className="dywany-ul-li">
            We use cleaning agents that do not damage the structure of carpets
            and rugs
          </li>
          <li className="dywany-ul-li">
            We will carry out cleaning quickly and efficiently, we will adjust
            the dates at a time convenient for you (also on weekends)
          </li>
          <li className="dywany-ul-li">
            We offer discounts for regular customers and for larger orders
          </li>
          <li className="dywany-ul-li">
            We guarantee quality and competitive prices!
          </li>
        </ul>
        <h3>The scope of our services includes:</h3>
        <ol>
          <li className="dywany-ol-li">
            Washing and cleaning of carpets and rugs, selecting the appropriate
            agent depending on the fiber
          </li>
          <li className="dywany-ol-li">Removing stains and difficult dirt</li>
          <li className="dywany-ol-li">Rinsing out the cleaning agents</li>
          <li className="dywany-ol-li">Neutralization of chemical residues</li>
          <li className="dywany-ol-li">
            On request, we will apply a professional protective agent that
            repels dirt and water, helping to keep carpets and rugs clean and
            fresh for longer.
          </li>
          <li className="dywany-ol-li">
            Pre-drying to shorten the complete drying time
          </li>
        </ol>
        <p className="dywany-end-p1">
          Trust our specialists and enjoy a clean, fragrant interior.
        </p>
        <p className="dywany-end-p2">
          We will take care of your comfort - your carpets and rugs will be as
          good as new!
        </p>
        <h3 className="dywany-h3">
          We invite you to cooperate! <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </h3>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
