import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './ListUslugi.css';

function LineUnderText() {
  return <div className="uslugi__line-under"></div>;
}
function UslugiList() {
  const [isScrollUp, setScrollUp] = useState(true);

  useEffect(() => {
    if (isScrollUp) {
      window.scroll({ top: 0, behavior: 'smooth' });
      setScrollUp(false);
    }
  }, [isScrollUp]);
  return (
    <>
      <div className="uslugi-list">
        <span>Uslugi</span>
        <ul>
          <Link onClick={() => setScrollUp(true)} to="/usluga1">
            <li>Pranie kanapy</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/usluga2">
            <li>Pranie kszesla</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/usluga4">
            <li>Pranie wykładziny, dywanu </li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/usluga3">
            <li>Pranie tapicerki samochodowej</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/usluga5">
            <li>Сzyszczenie materacy</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/usluga8">
            <li>Czyszczenie i pielęgnacja tapicerki skórzanej</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/usluga7">
            <li>Impregnowanie i zabezpieczanie powłoką ochronną tapicerek</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/usluga6">
            <li>Mycie okien</li>
            <LineUnderText />
          </Link>
        </ul>
      </div>
    </>
  );
}
export function UslugiListEn() {
  const [isScrollUp, setScrollUp] = useState(true);

  useEffect(() => {
    if (isScrollUp) {
      window.scroll({ top: 0, behavior: 'smooth' });
      setScrollUp(false);
    }
  }, [isScrollUp]);
  return (
    <>
      <div className="uslugi-list">
        <span>Services</span>
        <ul>
          <Link onClick={() => setScrollUp(true)} to="/en/usluga1">
            <li>Washing couches</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/en/usluga2">
            <li>Washing chairs</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/en/usluga3">
            <li>Washing car upholstery</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/en/usluga4">
            {' '}
            <li>Washing carpets and rugs</li>
            <LineUnderText />
          </Link>{' '}
          <Link onClick={() => setScrollUp(true)} to="/en/usluga5">
            <li>Mattress cleaning</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/en/usluga8">
            {' '}
            <li>Cleaning and care of leather upholstery</li>
            <LineUnderText />
          </Link>
          <Link onClick={() => setScrollUp(true)} to="/en/usluga7">
            {' '}
            <li>
              Impregnating and protecting upholstery with a protective coating
            </li>
          </Link>{' '}
          <Link onClick={() => setScrollUp(true)} to="/en/usluga6">
            {' '}
            <li>Window washing</li>
            <LineUnderText />
          </Link>{' '}
        </ul>
      </div>
    </>
  );
}
export default UslugiList;
