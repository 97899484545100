import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/clean_matrac.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2>
          Czyszczenie materacy
          <span className="uslugi__text-title"></span>
        </h2>
        <p>Chcesz wyczyścić lub odświeżyć swój materac?</p>
        <p>Nie ma sprawy! </p>
        <p>Firma Dobra Atmosfera oferuje profesjonalne czyszczenie materacy.</p>
        <p>Czy wiesz skąd brud na materacu? </p>
        <p>
          Przecież zawsze jest on przykryty pościelą, a wiele osób używa także
          ochraniaczy na materac. Zdziwisz się, gdy zobaczysz, jak brudna może
          być woda w ekstraktorze po czyszczeniu materaca! Tłuste ślady po
          jedzeniu, mieszanki dziecięce, mocz, kawa, plamy garbnikowe, smugi
          niewiadomego pochodzenia – to najczęstsze zanieczyszczenia, z którymi
          mierzymy się.
        </p>
        <p>
          Nie czyść plam na własną rękę przed profesjonalną usługą! Najczęściej
          nasi specjaliści muszą naprawiać skutki prób „wywabienia” lub
          „wytarcia” plam. Takie działania powodują powstawanie plam
          garbnikowych i niszczenie materiału pokrowca. Posiadamy bogate
          doświadczenie w walce z wszelkiego rodzaju zabrudzeniami. Mamy do
          dyspozycji profesjonalny sprzęt i wysokiej jakości ekologiczną chemię.
          Zaletą profesjonalnego czyszczenia jest jego bezpieczeństwo dla
          zdrowia wszystkich członków rodziny, zwierząt domowych oraz materiałów
          obicia.
        </p>
        <p>Etapy czyszczenia materacy, których przestrzegamy:</p>
        <ol>
          <li>Usuwanie kurzu z obicia</li>
          <li>Wstępne namaczanie</li>
          <li>Usuwanie podstawowych zabrudzeń</li>
          <li>Usuwanie trudnych zabrudzeń</li>
          <li>Czyszczenie i płukanie obicia materaca</li>
          <li>Suszenie materaca</li>
        </ol>
        <p>
          Czyszczenie na miejscu – to wygodne rozwiązanie. Oszczędzamy Twój
          czas, chronimy nerwy i siły.
        </p>
        <p> Zapraszamy do kontaktu!</p>
        <p className="uslugi-notes">
          Zapraszamy do kontaktu i uzyskania indywidualnej oferty: <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
