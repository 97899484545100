import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/tapicerka.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2>
          Washing car upholstery
          <span className="uslugi__text-title">Cleaning seats</span>
        </h2>
        <p>
          Don't you feel fresh in the car showroom? Are there unpleasant odors
          that even an air freshener cannot cover? Has the seat upholstery lost
          its shine? Unsightly stains appear on the upholstery?
        </p>
        <p>
          Dobra Atmosfera offers professional washing and cleaning of your car's
          upholstery.
        </p>
        <h3>What does upholstery and armchair cleaning include?</h3>
        <ol className="usluga-car-ol">
          <li>Dust removal</li>
          <li>Washing with professional equipment and chemicals</li>
          <li>Stain removal</li>
          <li>Odor elimination</li>
          <li>Rinsing</li>
          <li>Neutralization of chemicals</li>
          <li>Drying</li>
        </ol>
        <p>
          We use leather protection for leather items. Special creams and balms
          help restore the structure of the skin, increasing its resistance to
          abrasion, dirt and other external factors.
        </p>
        <p className="uslugi-notes">
          Please contact us and obtain an individual offer: <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
