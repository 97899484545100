import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/clean_sofa.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2>
          Pranie kanapy
          <span className="uslugi__text-title"> Sofy, wersalki, narożnika</span>
        </h2>
        <p>
          Szukasz wysokiej jakości usługi czyszczenia swojej kanapy? Dobrze
          trafiłeś!
        </p>
        <p>
          Firma Dobra Atmosfera ofiaruje profesjonalne pranie kanap, sof,
          wersalek, narożników. Oczyścimy zarówno zwykłe, codzienne zabrudzenia,
          jak i trudne plamy z krwi, kleju, herbaty, kawy, wina, atramentu,
          owoców, jagód. <br />
          Usuniemy z Twojej kanapy nieprzyjemny zapach moczu, pleśni czy
          wymiocin.
        </p>

        <p>
          Naszym głównym celem jest sprawienie, aby Twoja kanapa była jak
          najdłużej czysta i bezpieczna w użytkowaniu! Do tego celu
          wykorzystujemy profesjonalny sprzęt oraz najlepsze na rynku środki
          czyszczące.
        </p>
        <p>
          Na życzenie{'  '}
          <strong>zastosujemy specjalistyczny preparat ochronny</strong>, który
          odpycha brud i wodę, pomagając utrzymać czystość i świeżość kanapy
          przez długi czas.
        </p>
        <p>
          Dodatkowo <strong>podsuszymy kanapę</strong>, aby skrócić czas jej
          całkowitego wysychania. Wybierając nasze usługi, masz pewność, że
          Twoja kanapa odzyska świeży wygląd i będzie służyła przez długie lata.
        </p>
        <p>
          Skontaktuj się z nami już dziś, aby poznać szczegóły oferty i umówić
          się na wizytę.
        </p>
        <p className="uslugi-notes">
          Zapraszamy do kontaktu i uzyskania indywidualnej oferty: <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
