import './WhatsappLink.css';
import { Link } from 'react-router-dom';
import whatsapp from '../img/whatsup/whatsapp.png';
import Img_phone from '../img/whatsup/telephone.png';

function WhatsApp() {
  return (
    <>
      <div className="whatsappLink">
        <Link to="https://wa.me/48790766496">
          <img src={whatsapp} alt="WhatsApp" title="WhatsApp" />
        </Link>
      </div>
      <div className="telephone">
        <Link to={'tel:+48790766496'} className="header__phone-link">
          <img
            title="phone"
            src={Img_phone}
            alt="phone"
            className="header__phone-img"
          />
        </Link>
      </div>
    </>
  );
}

export default WhatsApp;
