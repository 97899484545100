import './Services.css';
import main1 from '../../../img/Services/clean_sofa.jpg';
import main4 from '../../../img/Services/clean_carpet3.jpg';
import main3 from '../../../img/Services/tapicerka.jpg';
import main2_1260px from '../../../img/Services/usluga2_1260px.png';
import main5 from '../../../img/Services/clean_matrac.jpg';
import main6 from '../../../img/Services/window5.jpg';
import main7 from '../../../img/Services/impresions.jpg';
import main8 from '../../../img/Services/clean_leather.jpg';
import { Link } from 'react-router-dom';

// function TitleServiceMore860px() {
//   return (
//     <div className=" main-services">
//       <h3 className="h3">PROFESJONALNE PRANIE</h3>
//       <h1 className="h1">
//         KANAPY, FOTELE, MATERACE, DYWANY, <br />
//         WYKŁADZINY, WÓZKI, ZASŁONY, <br />
//         TAPICERKA SAMOCHODOWA...
//       </h1>
//       <h2 className="h2">
//         👉 GWARANTUJEMY ZWROT 100% OPŁATY, JEŚLI KLIENT NIE BĘDZIE ZADOWOLONY Z
//         EFEKTU PRANIA !!!
//       </h2>
//     </div>
//   );
// }
function TitleServiceless850px() {
  return (
    <div className=" main-services">
      <h3 className="h3">PROFESJONALNE PRANIE</h3>
      <h1 className="h1">
        <span className="titlerow">KANAPY, FOTELE, MATERACE, DYWANY,</span>
        <span className="titlerow">WYKŁADZINY, WÓZKI, ZASŁONY,</span>
        <span className="titlerow">TAPICERKA SAMOCHODOWA...</span>
      </h1>
      <h2 className="h2">
        👉 GWARANTUJEMY ZWROT 100% OPŁATY, JEŚLI KLIENT NIE BĘDZIE ZADOWOLONY Z
        EFEKTU PRANIA !!!
      </h2>
    </div>
  );
}

function Services() {
  return (
    <>
      <div className="main__first-services">
        <TitleServiceless850px />
        <div className="main__services">
          <Link to="usluga1">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img">
                <img src={main1} alt="photo1" />
              </div>
              <h2>
                Pranie kanapy
                <span className="main__services-item-span">
                  Sofy, wersalki, narożnika
                </span>
              </h2>

              <button type="button" className="main-services-price">
                od 150 zł
              </button>
            </div>
          </Link>

          <Link to="usluga2">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img usluga2-bock-wide">
                <img src={main2_1260px} alt="photo1" className="usluga2-img" />
              </div>
              <h2>
                PRANIE KRZESŁA
                <span className="main__services-item-span">
                  Fotela, krzesła biurowego, pufa
                </span>
              </h2>
              <button type="button" className="main-services-price">
                od 20 zł
              </button>
            </div>
          </Link>
          <Link to="usluga3">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img">
                <img src={main3} alt="photo1" />
              </div>
              <h2>
                Pranie tapicerki samochodowej
                <span className="main__services-item-span">
                  Czyszcenie foteli
                </span>
              </h2>

              <button type="button" className="main-services-price">
                od 50 zł
              </button>
            </div>
          </Link>
          <Link to="usluga4">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img">
                <img src={main4} alt="photo1" />
              </div>
              <h2>
                PRANIE <br /> WYKŁADZINY, DYWANU
                <span className="main__services-item-span"></span>
              </h2>

              <button type="button" className="main-services-price">
                od 8 zł
              </button>
            </div>
          </Link>
          <Link to="usluga5">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img">
                <img src={main5} alt="photo1" />
              </div>
              <h2>
                CZYSZCZENIE MATERACY
                <span className="main__services-item-span"></span>
              </h2>

              <button type="button" className="main-services-price">
                od 100 zł
              </button>
            </div>
          </Link>
          <Link to="usluga8">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img">
                <img src={main8} alt="photo1" />
              </div>
              <h2>
                czyszczenie i pielęgnacja tapicerki skórzanej
                <span className="main__services-item-span"></span>
              </h2>

              <button type="button" className="btn-service"></button>
            </div>
          </Link>
          <Link to="usluga7">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img">
                <img src={main7} alt="photo1" />
              </div>
              <h2>
                impregnowanie I zabezpieczanie powłoką ochronną tapicerek
                <span className="main__services-item-span"></span>
              </h2>

              <button type="button" className="btn-service">
                od 100 zł
              </button>
            </div>
          </Link>
          <Link to="usluga6">
            <div className="main__services-item">
              <div className="main__services-backdrop"></div>
              <div className="main__services-img">
                <img src={main6} alt="photo1" />
              </div>
              <h2>
                mycie okien
                <span className="main__services-item-span"></span>
              </h2>

              <button type="button" className="main-services-price">
                od 30 zł
              </button>
            </div>
          </Link>
        </div>
        <div className="main__link-details">
          <Link to="/price">Szczegółowo...</Link>
        </div>
      </div>
    </>
  );
}
export default Services;
