import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/clean_chair.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2 className="uslugi__text-h2">
          WASHING THE CHAIR
          <span className="uslugi__text-title">
            armchair, office chair, pouffe
          </span>
        </h2>
        <p>
          Do you have dirty chairs or armchairs at home or office? Make an
          appointment with our employee and be sure that we will remove the dirt
          safely for the fabrics and residents.
        </p>
        <p>
          The Dobra Atmosfera company offers professional washing of chairs,
          office chairs, armchairs and pouffes.
        </p>

        <p>
          Thanks to modern technologies and our experience, we guarantee perfect
          cleanliness and extend the life of your furniture.
        </p>
        <p>We clean furniture made of fabrics, leather and eco-leather.</p>
        <h3>Why choose us?</h3>
        <p className="p-span">
          Quality:
          <span>
            We use safe and effective cleaning agents that do not damage
            materials.
          </span>
        </p>
        <p className="p-span">
          Speed:
          <span>
            We perform the work efficiently, minimizing disruptions to the
            functioning of the office.
          </span>
        </p>
        <p className="p-span">
          Accessibility:
          <span>
            Flexible terms of cooperation and transparent pricing policy.
          </span>
        </p>
        <p className="p-span">
          Effect guarantee:
          <span>
            We remove stains, unpleasant odors and restore freshness to
            furniture.
          </span>
        </p>

        <h3>The scope of our services includes:</h3>
        <ol className="noitalic-ol-ul">
          <li>
            Washing and cleaning armchairs and chairs using professional
            chemicals
          </li>
          <li> Removing stains and heavy dirt </li>
          <li>Rinsing out chemicals </li>
          <li>Neutralization of chemical residues</li>
          <li>
            On request, we will apply a professional protective agent to the
            furniture that repels dirt and water and helps keep it clean and
            fresh for longer.
          </li>
          <li>
            Dry the furniture to shorten the time it takes to dry completely.
          </li>
        </ol>
        <h3>Terms of cooperation:</h3>
        <ul>
          <li>
            Individual pricing, adapted to the quantity and specificity of the
            furniture.
          </li>
          <li>
            Possibility of providing services at a time convenient for you (also
            on weekends).
          </li>
          <li>Discounts for regular customers and larger orders.</li>
        </ul>
        <p>
          Trust professionals and keep your chairs and armchairs clean! We will
          be happy to answer any questions and prepare an offer tailored to your
          needs.
        </p>
        <p className="uslugi-notes">
          Please contact us: <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </p>
      </div>
    </>
  );
}
// i uzyskania indywidualnej oferty
function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
