import { NavLink } from 'react-router-dom';
import { useProject } from '../../../tools/ProviderContext';
// import css from './Nav.module.css';
import './Nav.css';
import './Nav.css';
function Nav() {
  const { closeBurgerMenu } = useProject();
  return (
    <>
      <nav className="menu">
        <ul className={'menu__list'}>
          <li className={'menu__item'}>
            <NavLink to="/" className="menu__link" onClick={closeBurgerMenu}>
              Główna
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/about"
              onClick={closeBurgerMenu}
              className="menu__link"
            >
              O nas
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/price"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Cennik
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/article1"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Artykuły
            </NavLink>
          </li>
          <li className={'menu__item'}>
            <NavLink
              to="/contact"
              className="menu__link"
              onClick={closeBurgerMenu}
            >
              Kontakt
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
}
export default Nav;
