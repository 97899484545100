import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/clean_carpet3.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2>
          PRANIE WYKŁADZINY I DYWANU
          <span className="uslugi__text-title"> </span>
        </h2>
        <p>
          Potrzebujesz profesjonalnego czyszczenia wykładziny lub dywana?
          Trafiłeś we właściwe miejsce!
        </p>
        <p>
          Firma Dobra Atmosfera oferuje profesjonalne usługi czyszczenia
          wykładziny i dywanu w twoim domu lub biurze.
        </p>
        <p>
          Dzięki nowoczesnym technologiom skutecznie usuwamy codzienne
          zabrudzenia, plamy, kurz, nieprzyjemne zapachy, przywracając świeżość
          i estetyczny wygląd powierzchni.
        </p>
        <h3>Dlaczego warto nam zaufać?</h3>
        <ul>
          <li className="dywany-ul-li">
            Używamy środków czyszczących , które nie niszczą struktury wykładzin
            i dywanów
          </li>
          <li className="dywany-ul-li">
            Szybko i sprawnie zrealizujemy czyszczenie, dostosujemy terminy w
            dogodnym dla państwa czasie (również w weekendy)
          </li>
          <li className="dywany-ul-li">
            Oferujemy zniżki dla stałych klientów oraz przy większych
            zamówieniach.
          </li>
          <li className="dywany-ul-li">
            Gwarantujemy jakość i konkurencyjne ceny!
          </li>
        </ul>
        <h3>Zakres naszych usług obejmuje:</h3>
        <ol>
          <li className="dywany-ol-li">
            Pranie i czyszczenie dywanów i wykładzin z wybraniem odpowiedniego
            środka w zależności od włókna
          </li>
          <li className="dywany-ol-li">Usuwanie plam i trudnych zabrudzeń</li>
          <li className="dywany-ol-li">Wypłukanie środków czyszczących</li>
          <li className="dywany-ol-li">
            Neutralizację pozostałości chemicznych
          </li>
          <li className="dywany-ol-li">
            Na życzenie zastosujemy profesjonalny środek ochronny, który odpycha
            brud i wodę, pomagając utrzymać czystość i świeżość dywanuw i
            wykładzin na dłużej
          </li>
          <li className="dywany-ol-li">
            Podsuszanie, aby skrócić czas całkowitego wysychania
          </li>
        </ol>
        <p className="dywany-end-p1">
          Zaufaj naszym specjalistom i ciesz się czystym, pachnącym wnętrzem.
        </p>
        <p className="dywany-end-p2">
          Zadbamy o Twój komfort – Twoje wykładziny i dywany będą jak nowe!
        </p>
        <h3 className="dywany-h3">
          Zapraszamy do współpracy! <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </h3>
        {/* <p className="uslugi-notes"> */}
        {/* Zapraszamy do kontaktu i uzyskania indywidualnej oferty: <br /> */}
        {/* <span>
          Tel./SMS/MMS/WhatsApp:{' '}
          <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
            +48 790 766 496.
          </Link>{' '}
        </span> */}
        {/* </p> */}
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
