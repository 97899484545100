import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/impresions.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2>
          impregnowanie I zabezpieczanie powłoką ochronną tapicerek
          <span className="uslugi__text-title"> </span>
        </h2>
        <p>
          Czy wiesz, że odpowiednia ochrona tapicerki i dywanów pozwala cieszyć
          się ich pięknym wyglądem i funkcjonalnością przez długie lata?
          Impregnacja to skuteczny sposób na zabezpieczenie materiału przed
          plamami, wilgocią i uszkodzeniami, a także na ułatwienie codziennej
          pielęgnacji.
        </p>
        <h3>Dlaczego impregnacja to konieczność?</h3>

        <p>
          <em>Brak stresu przy rozlanych płynach</em> – powłoka ochronna
          zapobiega wnikaniu cieczy.
        </p>
        <p>
          <em>Meble odporne na codzienne użytkowanie</em> – mniej ścierania i
          zniszczeń.
        </p>
        <p>
          <em>Zadbane wnętrze bez wysiłku</em> – czyszczenie staje się proste i
          szybkie.
        </p>

        <h3>Dlaczego warto zaufać nam?</h3>
        <p>
          Jesteśmy ekspertami w ochronie tapicerki. Korzystamy z nowoczesnych
          technologii i bezpiecznych preparatów, które działają skutecznie, a
          jednocześnie są delikatne dla materiałów.
        </p>
        <p>
          Zabezpiecz swoje meble i dywany na przyszłość – wybierz impregnację!
        </p>
        <p className="uslugi-notes">
          Zapraszamy do kontaktu i uzyskania indywidualnej oferty: <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
