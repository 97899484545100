import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/tapicerka.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2>
          Pranie tapicerki samochodowej
          <span className="uslugi__text-title"> Czyszczenie foteli</span>
        </h2>
        <p>
          Nie czujesz świeżości w salonie samochodu? Pojawiły się nieprzyjemne
          zapachy, których nie maskuje nawet odświeżacz powietrza? Tapicerka
          foteli straciła blask? Na obiciach pojawiły się nieestetyczne plamy?
        </p>
        <p>
          Firma Dobra Atmosfera oferuje profesjonalne pranie i czyszczenie
          tapicerki Twojego auta.
        </p>
        <h3>Co obejmuje czyszczenie tapicerki i foteli?</h3>
        <ol className="usluga-car-ol">
          <li>Usuwanie kurzu</li>
          <li>Mycie profesjonalnym sprzętem i środkami chemicznymi</li>
          <li>Usuwanie plam</li>
          <li>Eliminacja zapachów</li>
          <li>Płukanie</li>
          <li>Neutralizacja chemii</li>
          <li>Podsuszenie</li>
        </ol>
        <p>
          Dla skórzanych elementów używamy ochrony dla skóry. Specjalne kremy i
          balsamy pomagają przywrócić strukturę skóry, zwiększając jej odporność
          na ścieranie, zabrudzenia i inne czynniki zewnętrzne.
        </p>
        <p className="uslugi-notes">
          Zapraszamy do kontaktu i uzyskania indywidualnej oferty: <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
