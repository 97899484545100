import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/window5.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2>
          Mycie okien
          <span className="uslugi__text-title"> </span>
        </h2>
        <p>
          Twoje okna zabrudzone i brzydko wyglądają?
          <span className="nomargin-top-p-moikavikon">Dobrze trafiłeś!</span>
        </p>

        <p>
          Firma Dobra Atmosfera oferuje usługę mycia okien w Twoim domu lub
          biurze.
        </p>
        <p>Myjemy okna tak dokładnie, jakbyśmy robili to dla siebie.</p>
        <p>Zainwestuj w czystość, która robi wrażenie!</p>
        <p className="uslugi-notes">
          Zapraszamy do kontaktu i uzyskania indywidualnej oferty: <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
