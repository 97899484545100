import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import pranieImg from '../../../img/uslugi/clean_matrac.jpg';
import './Uslugi.css';
import { Link } from 'react-router-dom';

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <img src={pranieImg} alt="img pranie" />
        <h2>
          Mattress cleaning
          <span className="uslugi__text-title"></span>
        </h2>
        <p>Do you want to clean or refresh your mattress?</p>
        <p>No problem!</p>
        <p>Dobra Atmosfera offers professional mattress cleaning.</p>
        <p>Do you know where the dirt on the mattress comes from?</p>
        <p>
          After all, it is always covered with bedding, and many people also use
          mattress protectors. You will be surprised to see how dirty the water
          in the extractor can be after cleaning the mattress! Greasy traces of
          food, baby formula, urine, coffee, tannin stains, streaks of unknown
          origin - these are the most common contaminants we deal with.
        </p>
        <p>
          Do not clean stains on your own before a professional service! Most
          often, our specialists have to repair the effects of attempts to
          "remove" or "wipe" stains. Such actions cause the formation of tannin
          stains and the destruction of the cover material. We have extensive
          experience in the fight against all types of dirt. We have
          professional equipment and high-quality ecological chemicals at our
          disposal. The advantage of professional cleaning is its safety for the
          health of all family members, pets and upholstery materials.
        </p>
        <p>Mattress cleaning stages we follow:</p>
        <ol>
          <li>Removing dust from upholstery</li>
          <li>Pre-soaking</li>
          <li>Removing basic dirt</li>
          <li>Removing difficult dirt</li>
          <li>Cleaning and rinsing the mattress cover</li>
          <li>Drying the mattress</li>
        </ol>
        <p>
          Cleaning in place – it's a convenient solution. We save your time,
          protect your nerves and strength.
        </p>
        <p> Please contact us!</p>
        <p className="uslugi-notes">
          Please contact us and obtain an individual offer: <br />
          <span>
            Tel./SMS/MMS/WhatsApp:{' '}
            <Link className="uslugi-notes-phone" to={'tel:+48790766496'}>
              +48 790 766 496.
            </Link>{' '}
          </span>
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
